import {useState} from 'react';

import styles from '../modules/popover.module.css'
import '../modules/popover.module.css'

function Popover() {

   const [popupVisible, setPopupVisible ] = useState(false);


   function openPopup()
   {
      // var a=document.getElementById("detailsframe");
      setPopupVisible(!popupVisible);
      // a.setAttribute("class","popup")
      
   }

    return (
      <>
       <button className={styles.click} onClick={openPopup}>Click</button>
        <div id="detailsframe" className={`${styles.initpop} ${popupVisible ? styles.popup : null}`} >
       </div> 
       <iframe className={styles.iframe} id="init" src="./pages/details.html" frameBorder="0" title='iframe'></iframe>
    
     </>
    );
  }
  
  export default Popover;  
                          
       
       
       


/*
       <div id="detailsframe" className={`initpop ${popupVisible ? 'popup' : ''}`} >
       </div>
       */
  