import '../resources/apty-template.png'
import styles from '../modules/body.module.css'
import Navigation from './navigation';
import Footer from './footer';
import Section from './section'
import Header from './header'
import { Container } from '@mui/material';
//import Iframe from './iframe'

function Main() {

  return (
    
    <div  className={styles.background}>
      
    <Header/>
    <Navigation />
    <Container > 
    <Section />
    </Container>
    <Footer />   
    
    
    </div>
     
  );
}
export default Main;

