import './App.css';

import Main from './components/main';
import { Routes, Route } from "react-router-dom";
import DynamicSelectors from './pages/dynamic-selectors'
import Popover from './pages/popover';
import MultiLevelIframe from './pages/MultiLevelIframe'


function App() {

  return (
    
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="DynamicSelectors" element={<DynamicSelectors />} />
      <Route path="MultiLevelIframe" element={<MultiLevelIframe />} />
      <Route path="/Popover" element={<Popover />} />
      
      
    </Routes> 

    
  );
}

export default App;
