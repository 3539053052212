
function MultiLevelIframe() {

  return (
    <>
      <h1 >Multi-Level Iframes</h1>
      <pre> 
        <iframe src="./pages/details.html" height="400" width="400"/>
        {`
      
      
      
`}
        <iframe src="./pages/singleLevelframe.html" height="400" width="500" />
        {`
      
      
      

`}
        <iframe src="./pages/doubleLevelIframe.html" height="500" width="600" />
        {`
      
      
      

`}
        
      </pre>

    </>
  );
}

export default MultiLevelIframe;
