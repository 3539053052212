import styles from '../modules/navigation.module.css'

function Navigation() {

  return (
    <nav role="navigation">
    
      <ul >
        <li className={styles.textsection}>
          <a href="#text" id={styles.text_navheader}>Text</a>
          <div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__headings">Headings</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__paragraphs">Paragraphs</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__blockquotes">Blockquotes</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__lists">Lists</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__hr">Horizontal rules</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__tables">Tabular data</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__code">Code</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__inline">Inline elements</a></div>
            <div className={styles.textsubsection}><a className={styles.links} href="#text__comments">HTML Comments</a></div>
          
        </div>
        </li>

        <li className={styles.embeddsection}>
          <a href="#embedded" id={styles.embedd_navheader}>Embedded content</a>
          <ul>
            <div>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__images">Images</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__audio">Audio</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__video">Video</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__canvas">Canvas</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__meter">Meter</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links}href="#embedded__progress">Progress</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__svg">Inline SVG</a></li>
              <li className={styles.embeddsubsection}><a className={styles.links} href="#embedded__iframe">IFrames</a></li>
            </div>
          </ul>
        </li>
        <li className={styles.formsection}>
          <a href="#forms" id={styles.form_navheader}>Form elements</a>
          <ul>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__input">Input fields</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__select">Select menus</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__checkbox">Checkboxes</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__radio">Radio buttons</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__textareas">Textareas</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__html5">HTML5 inputs</a></li>
            <li className={styles.formsubsection}><a className={styles.links} href="#forms__action">Action buttons</a></li>
          </ul>
  </li>
      </ul>
    </nav>
    
  );
}

export default Navigation;
